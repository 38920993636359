import React from 'react'
import theme from 'lib/styles/theme'

export default ({ fill = theme.colors.black, size = 12, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    version="1.0"
    width={size}
    height={size}
    fill={fill}
    {...rest}
  >
    <path d="M895 405H105a95 95 0 0 0 0 190h790a95 95 0 0 0 0-190z" />
  </svg>
)
