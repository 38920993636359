import React, { useEffect } from 'react'
import { inject } from 'mobx-react'
import Layout from 'layouts/default'
import Wrap from 'components/wrap'
import Title from 'components/title'
import Cart from 'components/cart'

const CartPage = ({ global: globalStore }) => {
  useEffect(() => {
    globalStore.addMessage(
      "Sorry. Our store is disabled, but it's coming soon.",
    )
  }, [globalStore])

  return (
    <Layout path="/cart">
      <Wrap>
        <Title is="h1">Shopping Cart</Title>
        <Cart />
      </Wrap>
    </Layout>
  )
}

export default inject('global')(CartPage)
