import React from 'react'
import { observer, inject } from 'mobx-react'
import isEmpty from 'lodash.isempty'
import styled from 'styled-components'
import { rem, flex, is } from 'styled-tidy'
import { Link } from 'gatsby'
import cartConfig from 'config/cart'
import { formatPrice } from 'lib/common/helpers'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import X from 'components/icons/x'
import ArtSize from 'components/art-size'
import Button from 'components/button'
import Title from 'components/title'
import Price from 'components/price'
import Wrap from 'components/wrap'
import QuantityInput from 'components/quantity-input'

const { colors, fonts } = theme
const { white, pearl, smoke, vapor, tar } = colors

const CartWrapper = styled.div`
  border-collapse: collapse;
  margin: 0 ${rem(-16)} ${rem(32)};
  user-select: none;
  width: calc(100% + ${rem(32)});

  ${media.small`
    margin: 0 ${rem(24)} ${rem(32)};
    width: 100%;
  `}
`

const CartItemRow = styled.div`
  ${flex('row', 'flex-start', 'flex-start')}
  background: ${white};
  border-bottom: ${rem(4)} solid ${pearl};
  flex-direction: column;

  ${media.small`
    flex-direction: row;
  `}
`

const CartFooterRow = styled.div`
  background: ${white};
  border: solid ${smoke};
  border-width: ${rem(2)} 0;
  margin: ${rem(16)} 0;
  display: flex;

  > div {
    padding: ${rem(16)} ${rem(8)};

    :last-child {
      background: ${vapor};
    }
  }

  ${media.small`
    border-width: 0 0 ${rem(2)};
    margin: 0 0 ${rem(16)};

    > div:last-child {
      background: ${white};
    }
  `}
`

const CellGroup = styled.div`
  ${flex('row', 'stretch', 'flex-end')}
  border-top: ${rem(1)} solid ${pearl};
  width: 100%;

  > div:first-child {
    border-left-width: 0;
  }

  :last-child > div:last-child {
    background: ${vapor};
  }

  ${media.small`
    border-top: none;
    min-height: ${rem(88)};

    > div:first-child {
      border-left-width: ${rem(1)};
    }

    :last-child > div:last-child {
      background: ${white};
    }
  `}
`

const Cell = styled.div.attrs(({ minWidth }) => ({
  style: { minWidth },
}))`
  border-left: ${rem(1)} solid ${pearl};
  padding: ${({ padding }) => padding || rem(8)};
  text-align: ${({ align }) => align || 'left'};

  ${is('expand')`
    flex: 1;
  `}

  h3 {
    border: none;
    margin: 0 0 ${rem(8)};
    padding: 0;
    text-align: left;
  }

  > span {
    background: ${tar};
    color: ${pearl};
    display: inline;
    padding: ${rem(4)} ${rem(8)};
  }
`

const CellTitle = styled.div`
  flex: 1;
  font-size: ${rem(14)};
  font-family: ${fonts.primary};
  font-weight: 400;
  padding: ${rem(8)};
  text-align: ${({ align }) => align || 'left'};
  text-transform: uppercase;
`

const ThumbnailWrap = styled.div`
  ${flex('row', 'center', 'center')}
  padding: ${rem(8)};
  min-width: ${rem(88)};
  position: relative;
`

const Thumbnail = styled.img`
  display: block;
  max-height: ${rem(72)};
  max-width: ${rem(72)};
`

const QuantityWrap = styled.div`
  ${flex('row', 'center', 'flex-end')}
  line-height: 1;
  padding: 0;

  > svg {
    margin: 0 ${rem(4)};
  }

  ${media.small`
    min-width: ${rem(176)};
    padding: ${rem(8)};
  `}

  ${media.medium`
    min-width: ${rem(200)};
  `}
`

const PriceWrap = styled.div`
  ${flex('row', 'center', 'flex-end')}
  align-items: center;
  height: 100%;

  > div {
    font-size: ${rem(20)};
  }

  ${media.small`
    align-items: flex-start;
  `}
`

const CheckoutButtonWrap = styled.div`
  ${flex('row', 'center', 'flex-end')}
  padding: ${rem(8)};

  button {
    margin-right: ${rem(8)};
  }

  a {
    width: 100%;
  }

  ${media.small`
    padding: ${rem(12)} 0;

    a {
      width: ${rem(160)};
    }
  `}
`

const Cart = ({ cart: cartStore }) => {
  const { items, itemsTotal } = cartStore

  const quantityChangeHandler = sku => quantity => {
    cartStore.updateItemQuantity(sku, parseInt(quantity, 10))
  }
  const emptyCart = () => {
    cartStore.clearItems()
  }

  return items.length ? (
    <CartWrapper>
      {items.map(item => {
        const { product, print } = item
        const isPrint = !isEmpty(print)
        const productPath = `/products/${product.slug}`
        const { size, price } = cartStore.getPrintOrProduct(item)

        return (
          <CartItemRow key={`cart-row-item-${item.sku}`}>
            <CellGroup>
              <ThumbnailWrap>
                <Link to={productPath}>
                  <Thumbnail src={product.images[0]} alt="" />
                </Link>
              </ThumbnailWrap>
              <Cell expand>
                <Title is="h3" align="left" size={rem(14)} to={productPath}>
                  {product.name}
                  {isPrint ? ' ⋅ Print' : ''}
                </Title>
                {size && <ArtSize size={size} color={pearl} />}
              </Cell>
            </CellGroup>
            <CellGroup>
              <Cell align="right" minWidth={rem(160)} padding="0">
                <QuantityWrap>
                  {formatPrice(price, false)}
                  <X size={12} />
                  <QuantityInput
                    name={`cart-quantity-${item.sku}`}
                    min={0}
                    max={cartConfig.maxItemQuantity}
                    defaultValue={item.quantity}
                    onChange={quantityChangeHandler(item.sku)}
                  />
                </QuantityWrap>
              </Cell>
              <Cell align="right" minWidth={rem(96)}>
                <PriceWrap>
                  <Price cents={price * item.quantity} />
                </PriceWrap>
              </Cell>
            </CellGroup>
          </CartItemRow>
        )
      })}
      <CartFooterRow>
        <CellTitle align="right">Subtotal</CellTitle>
        <Cell minWidth={rem(96)}>
          <PriceWrap>
            <Price cents={itemsTotal} />
          </PriceWrap>
        </Cell>
      </CartFooterRow>
      <CheckoutButtonWrap>
        <Button kind="secondary" onClick={emptyCart}>
          Empty Cart
        </Button>
        <Button kind="primary" to="/checkout">
          Checkout
        </Button>
      </CheckoutButtonWrap>
    </CartWrapper>
  ) : (
    <Wrap>
      <p>Your cart is empty &#9785;</p>
    </Wrap>
  )
}

export default inject('cart')(observer(Cart))
