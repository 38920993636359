import React from 'react'
import theme from 'lib/styles/theme'

export default ({ fill = theme.colors.black, size = 12, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    version="1.0"
    width={size}
    height={size}
    fill={fill}
    {...rest}
  >
    <path d="M905 408H584l1-311v-5h-1c-3-44-40-79-85-79-44-1-81 34-84 78v317H94c-47-1-84 38-84 84 0 47 38 84 85 85l320-1v327c0 46 38 84 85 84 46 1 83-37 83-83l1-328h323c46 0 83-37 83-84 0-46-38-84-85-84z" />
  </svg>
)
