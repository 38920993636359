import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { rem, flex } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import Plus from 'components/icons/plus'
import Minus from 'components/icons/minus'

const { colors, speeds } = theme
const { white, vapor, pearl, smoke, ember, tar } = colors

const Wrapper = styled.div`
  ${flex('row', 'center', 'center')}
  background: ${vapor};
  background: linear-gradient(90deg, ${vapor}, ${white});
  border: solid ${pearl};
  border-width: 0 0 0 ${rem(1)};
  height: ${rem(48)};
  margin: 0 0 0 ${rem(4)};
  overflow: hidden;
  position: relative;
  width: ${rem(160)};

  ${media.small`
    border-width: ${rem(1)};
    width: ${rem(96)};
  `}
`

const Control = styled.a`
  ${flex('row', 'center', 'center')}
  background: ${pearl};
  border-left: ${rem(1)} solid ${smoke};
  cursor: pointer;
  flex-shrink: 0;
  height: ${rem(48)};
  text-align: center;
  transition: background ${speeds.quick}ms ease;
  user-select: none;
  width: ${rem(48)};

  svg {
    fill: ${ember};
    transition: fill ${speeds.quick}ms ease;
  }

  :hover {
    background: ${tar};
    z-index: 1;

    svg {
      fill: ${white};
    }
  }

  :active {
    padding: ${rem(2)} 0 0;
  }

  ${media.small`
    border-left: 0;
    position: absolute;
    right: 0;
    height: ${rem(24)};
    width: ${rem(24)};

    svg {
      transform: scale(0.75);
    }
  `}
`

const Increment = styled(Control)`
  ${media.small`
    bottom: 50%;
  `}
`

const Decrement = styled(Control)`
  ${media.small`
    top: 50%;
  `}
`

const StyledInput = styled.input.attrs({
  precision: 1,
  step: 1,
})`
  background: transparent;
  font-size: 100%;
  padding: ${rem(8)};
  text-align: center;
  width: calc(100% + ${rem(64)});

  /* Hide defaul number controls */
  -moz-appearance: textfield !important;
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
  }

  ${media.small`
    padding: ${rem(16)};
    text-align: left;
  `}
`

const QuantityInput = ({ name, onChange, defaultValue, min, max }) => {
  const [quantity, setQuantity] = useState()
  const inputRef = useRef(null)
  const [keepFocus, setKeepFocus] = useState(false)

  const updateQuantity = newValue => {
    const value = isNaN(newValue) ? defaultValue : newValue
    if (value === quantity) return
    setQuantity(value)
    onChange(value)
  }

  const increment = () => {
    const value = quantity + 1
    if (value > max) return
    setKeepFocus(false)
    updateQuantity(value)
  }

  const decrement = () => {
    const value = quantity - 1
    if (value < min) return
    setKeepFocus(false)
    updateQuantity(value)
  }

  const handleChange = event => {
    const value = parseInt(event.target.value, 10)
    inputRef.current.focus()
    setKeepFocus(true)
    updateQuantity(value)
  }

  useEffect(() => {
    if (isNaN(quantity)) setQuantity(defaultValue)
    if (keepFocus) inputRef.current.focus()
  }, [keepFocus, inputRef, quantity, defaultValue])

  return (
    <Wrapper>
      <StyledInput
        type="text"
        name={name}
        ref={inputRef}
        onChange={handleChange}
        value={quantity || defaultValue}
        maxLength={`${max}`.length}
        pattern="\d*"
      />
      <Decrement onClick={decrement}>
        <Minus />
      </Decrement>
      <Increment onClick={increment}>
        <Plus />
      </Increment>
    </Wrapper>
  )
}

export default QuantityInput
